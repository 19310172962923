<template>
  <div class="card p-shadow-6">
    <h1>Tickets</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <W4Mstreak
      :states="streakStatus"
      v-on:W4MStreakChangeSelected="streakSelectedChange"
    />
    <ChangeResponsible
      :showToast="true"
      :show="ticketChangeResponsible.show"
      :ticketId="ticketChangeResponsible.ticketId"
      :ticketSubject="ticketChangeResponsible.ticketSubject"
      :currentTechnician="ticketChangeResponsible.technicianId"
      :selected="ticketChangeResponsible.selected"
      v-on:cancel="changeResposibleCancel"
      v-on:changeTechnicianResp="changeResposibleOk"
    />
    <ChangeCategory
      :showToast="true"
      :show="ticketChangeCategory.show"
      :ticketId="ticketChangeCategory.ticketId"
      :ticketSubject="ticketChangeCategory.ticketSubject"
      :categoryId="ticketChangeCategory.categoryId"
      :selected="ticketChangeCategory.selected"
      v-on:cancel="changeCategoryCancel"
      v-on:changedTicketCategory="changeCategoryOk"
    />
    <ChangePriority
      :showToast="true"
      :show="ticketChangePriority.show"
      :ticketId="ticketChangePriority.ticketId"
      :ticketSubject="ticketChangePriority.ticketSubject"
      :priorityId="ticketChangePriority.priorityId"
      :selected="ticketChangePriority.selected"
      v-on:cancel="changePriorityCancel"
      v-on:changedTicketPriority="changePriorityOk"
    />
    <ChangeStatus
      :showToast="true"
      :show="ticketChangeStatus.show"
      :ticketId="ticketChangeStatus.ticketId"
      :ticketSubject="ticketChangeStatus.ticketSubject"
      :statusId="ticketChangeStatus.statusId"
      :selected="ticketChangeStatus.selected"
      v-on:cancel="changeStatusCancel"
      v-on:changedTicketStatus="changeStatusOk"
    />
    <MergeTickets
      :showToast="true"
      :show="showMergeTickets"
      :selected="selectedTickets"
      v-on:cancel="showMergeTickets = false"
      v-on:mergedTickets="mergedTicketsOk"
    />
    <div style="height: 65vh">
      <DataTable
        :value="tickets"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="status"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        removableSort
        groupRowsBy="ticket_status.name"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :selection.sync="selectedTickets"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm p-mr-1"
              label="Mostrar todos os tickets"
              @click="showAllTickets()"
            />
            <Button
              v-if="selectedTickets && selectedTickets.length > 1"
              icon="pi pi-sitemap"
              class="p-button-sm p-mr-1"
              label="Merge tickets"
              @click="showMergeTickets = true"
            />
            <span style="position: absolute; right: 10px">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="arquiveSearch"
                  type="text"
                  placeholder="Pesquisar"
                  class="p-inputtext-sm"
                  @blur="searchTickets"
                  @keyup.enter="searchTickets"
                />
              </span>
            </span>
          </div>
        </template>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ slotProps.data.ticket_status.name }}
          </div>
        </template>
        <Column
          selectionMode="multiple"
          :headerStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :filterHeaderStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :bodyStyle="{ 'min-width': '3%', 'max-width': '3%' }"
        ></Column>
        <Column
          sortable
          field="id"
          header="Id"
          filterField="id"
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Ticket'"
              @click="viewTicket(slotProps.data.id)"
              >{{ slotProps.data.id }}</span
            >
            <i
              class="pi pi-inbox p-ml-2"
              v-tooltip="'Arquivado'"
              v-if="slotProps.data.archived === true"
            ></i>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="client_email"
          header="Cliente"
          filterField="client_email"
          :headerStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :filterHeaderStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :bodyStyle="{ 'min-width': '12%', 'max-width': '12%' }"
        >
          <template #body="slotProps">
            {{ getInfoFromEmail(slotProps.data.client_email) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="subject"
          header="Assunto"
          filterField="subject"
          :headerStyle="{ 'min-width': '17%', 'max-width': '17%' }"
          :filterHeaderStyle="{ 'min-width': '17%', 'max-width': '17%' }"
          :bodyStyle="{ 'min-width': '17%', 'max-width': '17%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.subject }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          header="Notas"
          :headerStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :filterHeaderStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :bodyStyle="{
            'min-width': '3%',
            'max-width': '3%',
            'justify-content': 'center',
          }"
        >
          <template #body="slotProps">
            <i
              v-if="slotProps.data.ticket_notes.length > 0"
              class="pi pi-comments clickable-item"
              v-tooltip="'Ver Notas'"
              @click="viewNotes(slotProps.data)"
            ></i>
          </template>
        </Column>
        <Column
          field="status"
          header="Estado"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor(slotProps.data.ticket_status.name)"
            ></i>
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="changeStatus(slotProps.data)"
            >
              {{ slotProps.data.ticket_status.name }}
            </span>
            <span v-else>
              {{ slotProps.data.ticket_status.name }}
            </span>
          </template>
        </Column>
        <Column
          field="priority"
          header="Prioridade"
          filterField="priority"
          :headerStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :filterHeaderStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :bodyStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          sortable
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Prioridade'"
              @click="changePriority(slotProps.data)"
            >
              {{ slotProps.data.ticket_priority.name }}
            </span>
            <span v-else>
              {{ slotProps.data.ticket_priority.name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="priorityList"
              optionValue="priority"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          sortable
          field="category"
          header="Categoria"
          filterField="category"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.category
              "
              class="clickable-item"
              v-tooltip="'Alterar Categoria'"
              @click="changeCategory(slotProps.data)"
            >
              {{
                !slotProps.data.category
                  ? "_______"
                  : slotProps.data.ticket_category.name
              }}
            </span>
            <span v-else>
              {{ slotProps.data.ticket_category.name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="categoryList"
              optionValue="id"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          sortable
          field="assigned_to_name"
          header="Responsável"
          filterField="assigned_to_name"
          :headerStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :filterHeaderStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :bodyStyle="{ 'min-width': '9%', 'max-width': '9%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Técnico Responsável'"
              @click="changeResposible(slotProps.data)"
              >{{
                !slotProps.data.assigned_to
                  ? "_______"
                  : slotProps.data.ticket_assigned_user.username
              }}</span
            >
            <span v-else>{{
              slotProps.data.assigned_to
                ? slotProps.data.ticket_assigned_user.username
                : ""
            }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="office"
          header="Zona"
          filterField="office"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.office }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { zone: 'Faro', zoneValue: 'Faro' },
                { zone: 'Lisboa', zoneValue: 'Lisboa' },
                { zone: 'Porto', zoneValue: 'Porto' },
                { zone: 'Sem Zona', zoneValue: '___' },
              ]"
              optionValue="zoneValue"
              optionLabel="zone"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          sortable
          field="created_at"
          header="Criado"
          filterField="created_at"
          dataType="date"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            <span>{{ getDateTime(slotProps.data.created_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          sortable
          field="updated_at"
          header="Atualizado"
          filterField="updated_at"
          dataType="date"
          :headerStyle="{ 'min-width': '8.5', 'max-width': '8.5' }"
          :filterHeaderStyle="{ 'min-width': '8.5', 'max-width': '8.5' }"
          :bodyStyle="{ 'min-width': '8.5', 'max-width': '8.5' }"
        >
          <template #body="slotProps">
            <span>{{ getDateTime(slotProps.data.updated_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
      </DataTable>

      <Dialog
        :visible.sync="notes.show"
        :style="{ width: $isMobile() ? '100vw' : '50vw' }"
        :header="notes.ticket ? `Notas do Ticket ${notes.ticket.subject}` : ''"
        :modal="true"
        :contentStyle="{ overflow: 'hide' }"
      >
        <DataTable
          :value="notes.ticket.ticket_notes"
          responsiveLayout="scroll"
          class="p-datatable-sm"
        >
          <Column field="note" header="Nota">
            <template #body="slotProps">
              <div v-html="slotProps.data.note"></div>
            </template>
          </Column>

          <Column
            header="Autor"
            :bodyStyle="{ 'text-align': 'center' }"
            :headerClass="'align-center'"
          >
            <template #body="slotProps">
              {{ slotProps.data.user_created.username }}
            </template>
          </Column>
          <Column
            header="Data"
            :bodyStyle="{ 'text-align': 'center' }"
            :headerClass="'align-center'"
          >
            <template #body="slotProps">
              {{ getDateTime(new Date(slotProps.data.created_at)) }}
            </template>
          </Column>
        </DataTable>
        <template #footer>
          <Button
            label="Fechar"
            icon="pi pi-times"
            class="p-button-sm p-button-danger"
            @click="closeNotes"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import ticketsService from "../services/tickets.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import ChangeResponsible from "../components/helpdeskTickets/ChangeResponsible.vue";
import ChangeCategory from "../components/helpdeskTickets/ChangeCategory.vue";
import ChangePriority from "../components/helpdeskTickets/ChangePriority.vue";
import ChangeStatus from "../components/helpdeskTickets/ChangeStatus.vue";
import MergeTickets from "../components/helpdeskTickets/MergeTickets.vue";

export default {
  name: "Tickets",
  components: {
    W4Mstreak,
    ChangeResponsible,
    ChangeCategory,
    ChangePriority,
    ChangeStatus,
    MergeTickets,
  },
  data() {
    return {
      streakStatus: [],
      tickets: [],
      priorityList: [],
      categoryList: [],
      expandedRowGroups: [],
      loading: false,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        priority: { value: null, matchMode: FilterMatchMode.EQUALS },
        office: { value: null, matchMode: FilterMatchMode.EQUALS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
        subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        client_email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        assigned_to_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
        updated_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
      ticketChangeResponsible: {
        show: false,
        ticketId: null,
        technicianId: null,
        ticketSubject: null,
      },
      ticketChangeCategory: {
        show: false,
        ticketId: null,
        ticketSubject: null,
        categoryId: null,
      },
      ticketChangePriority: {
        show: false,
        ticketId: null,
        ticketSubject: null,
        priorityId: null,
      },
      ticketChangeStatus: {
        show: false,
        ticketId: null,
        ticketSubject: null,
        statusId: null,
      },
      showMergeTickets: false,
      showAll: false,
      timer: null,
      notes: { show: false, ticket: { ticket_notes: [] } },
      selectedTickets: null,
      arquiveSearch: null,
      currentState: {},
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    this.priorityList = await ticketsService.getPriorityList();
    this.categoryList = await ticketsService.getCategoryList();
    this.categoryList.push({ id: null, name: "Sem Categoria" });
    if (this.$route.params.status) {
      this.filters.technician.value = this.loggedUser.username;
    }
    this.loading = true;
    await this.initStreakStatus();
    this.loading = false;
    this.streakReload();
  },
  beforeUnmount() {
    return clearTimeout(this.timer);
  },
  methods: {
    streakReload() {
      this.initStreakStatus();
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.streakReload();
      }, 30 * 1000);
    },
    async initStreakStatus() {
      let news = await ticketsService.countByStatus(0);
      let awaitResponse = await ticketsService.countByStatus(1);
      let pendent = await ticketsService.countByStatus(2);
      let open = await ticketsService.countByStatus(4);
      let resolved = await ticketsService.countByStatus(3);
      // let closed = await ticketsService.countByStatus(5);
      let rejected = await ticketsService.countByStatus(6);

      let activeStatus = this.$route.params.status
        ? this.$route.params.status
        : "";
      this.streakStatus = [
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Novo",
          counter: news,
          active: activeStatus === "Novo",
          data: [],
        },
        {
          backgroudColor: "#FFA100",
          textColor: "#000",
          title: "Aberto",
          counter: open,
          active: activeStatus === "Aberto",
        },
        {
          backgroudColor: "#5F78DA",
          textColor: "#FFF",
          title: "Aguarda Resposta",
          counter: awaitResponse,
          active: activeStatus === "Aguarda Resposta",
        },
        {
          backgroudColor: "#8E9BEF",
          textColor: "#FFF",
          title: "Pendente",
          counter: pendent,
          active: activeStatus === "Pendente",
        },
        {
          backgroudColor: "#4455BD",
          textColor: "#FFF",
          title: "Resolvido",
          counter: resolved,
          active: activeStatus === "Resolvido",
        },
        // {
        //   backgroudColor: "#008340",
        //   textColor: "#FFF",
        //   title: "Fechado",
        //   counter: closed,
        //   active: activeStatus === "Fechado",
        // },
        {
          backgroudColor: "#ff0000",
          textColor: "#FFF",
          title: "Rejeitado",
          counter: rejected,
          active: activeStatus === "Rejeitado",
        },
      ];

      if (activeStatus != "") {
        let activeStatusIndex = this.getStreakStatusIndex(activeStatus);
        if (activeStatusIndex >= 0) {
          let streakInfo = {
            currentStateIndex: activeStatusIndex,
            currentState: activeStatus,
          };
          this.streakSelectedChange(streakInfo);
        }
      }
      return (this.loading = false);
    },
    getGroupheaderColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.ticket_status.name
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor(info) {
      let statusInfo = this.streakStatus.find((status) => status.title == info);
      return { color: statusInfo.backgroudColor };
    },
    rowExpand(event) {
      let streakInfo = {
        currentStateIndex: this.getStreakStatusIndex(event.data),
        currentState: event.data,
      };
      return this.streakSelectedChange(streakInfo);
    },
    async showAllTickets() {
      this.showAll = true;
      this.tickets = [];
      for (const [index, status] of this.streakStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange(
          {
            currentStateIndex: index,
            currentState: status.title,
          },
          true
        );
      }
    },
    getStreakStatusIndex(status) {
      return this.streakStatus.findIndex(
        (streakItem) => streakItem.title == status
      );
    },
    streakStatusChange(from, to) {
      let statusFrom = this.streakStatus.find((status) => status.title == from);
      if (statusFrom) {
        statusFrom.counter = --statusFrom.counter;
      }
      if (to == "") {
        return;
      }
      let statusTo = this.streakStatus.find((status) => status.title == to);
      if (statusTo) {
        statusTo.counter = ++statusTo.counter;
      }
    },
    removeByStatus(status) {
      this.tickets.forEach((ticket, index, object) => {
        if (ticket.ticket_status.name === status) {
          object.splice(index, 1);
        }
      });
    },
    streakSelectedChange(selected, notRemove) {
      // if (
      //   this.streakStatus[selected.currentStateIndex].loaded != undefined &&
      //   this.streakStatus[selected.currentStateIndex].loaded == true
      // ) {
      //   return;
      // }
      // this.streakStatus[selected.currentStateIndex].loaded = true;
      this.currentState = selected;
      this.currentState.notRemove = notRemove;
      this.selectedTickets = null;
      this.arquiveSearch = null;
      this.removeByStatus(selected.currentState);
      switch (selected.currentState) {
        case "Novo":
          this.getTicketsByStatus(selected.currentStateIndex, 0, notRemove);
          break;
        case "Aguarda Resposta":
          this.getTicketsByStatus(selected.currentStateIndex, 1, notRemove);
          break;
        case "Pendente":
          this.getTicketsByStatus(selected.currentStateIndex, 2, notRemove);
          break;
        case "Aberto":
          this.getTicketsByStatus(selected.currentStateIndex, 4, notRemove);
          break;
        case "Resolvido":
          this.getTicketsByStatus(selected.currentStateIndex, 3, notRemove);
          break;
        case "Fechado":
          this.getTicketsByStatus(selected.currentStateIndex, 5, notRemove);
          break;
        case "Rejeitado":
          this.getTicketsByStatus(selected.currentStateIndex, 6, notRemove);
          break;
        default:
          return;
      }
      return this.expandedRowGroups.push(selected.currentState);
    },
    async getTicketsByStatus(streakIndex, status, notRemove) {
      this.loading = true;
      let statusTicket = await ticketsService.getByStatus(status);
      statusTicket = statusTicket.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        created_at: new Date(`${v.created_at}`),
        updated_at: new Date(`${v.updated_at}`),
        assigned_to_name: v.assigned_to
          ? v.ticket_assigned_user.username
          : null,
        office: v.assigned_to ? v.ticket_assigned_user.office : "___",
      }));
      if (notRemove != undefined && notRemove == true) {
        this.tickets = this.tickets.concat(statusTicket);
      } else {
        this.showAll = false;
        this.tickets = statusTicket;
      }
      return (this.loading = false);
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    getInfoFromEmail(email) {
      if (!email) {
        return "";
      }
      let info = email.replace("@", " | ");
      info = info.replace(/[.,-/_]/g, " ");
      return info.substring(0, info.lastIndexOf(" "));
    },
    showStatusMenu(assist) {
      this.statusMenu.show = true;
      this.statusMenu.assist = assist;
    },
    closeStatusMenu() {
      this.statusMenu.show = false;
    },
    viewTicket(ticketId) {
      let route = this.$router.resolve({
        path: `/helpdesk-tickets-detail/${ticketId}`,
      });
      window.open(route.href, "_blank");
    },
    changeResposible(ticket) {
      return (this.ticketChangeResponsible = {
        show: true,
        ticketId: ticket.id,
        ticketSubject: ticket.subject,
        technicianId: ticket.assigned_to,
        ticket: ticket,
        selected:
          this.selectedTickets && this.selectedTickets.length > 1
            ? this.selectedTickets
            : null,
      });
    },
    changeResposibleCancel() {
      return (this.ticketChangeResponsible = {
        show: false,
        ticketId: null,
        ticketSubject: null,
        technicianId: null,
      });
    },
    changeResposibleOk(response) {
      if (response.status == "ok") {
        if (this.ticketChangeResponsible.selected) {
          for (var ticket of this.selectedTickets) {
            ticket.assigned_to = response.result.technician_id;
            ticket.assigned_to_name = response.result.responsible_user;
            ticket.office = response.result.office;
            ticket.ticket_assigned_user = {
              userno: response.result.responsible_user_id,
              username: response.result.responsible_user,
              tecnico: response.result.technician_id,
            };
          }
        } else {
          this.ticketChangeResponsible.ticket.assigned_to =
            response.result.technician_id;
          this.ticketChangeResponsible.ticket.assigned_to_name =
            response.result.responsible_user;
          this.ticketChangeResponsible.ticket.office = response.result.office;
          this.ticketChangeResponsible.ticket.ticket_assigned_user = {
            userno: response.result.responsible_user_id,
            username: response.result.responsible_user,
            tecnico: response.result.technician_id,
          };
        }
      }
      return this.changeResposibleCancel();
    },
    changeCategory(ticket) {
      return (this.ticketChangeCategory = {
        show: true,
        ticketId: ticket.id,
        ticketSubject: ticket.subject,
        categoryId: ticket.category,
        ticket: ticket,
        selected:
          this.selectedTickets && this.selectedTickets.length > 1
            ? this.selectedTickets
            : null,
      });
    },
    changeCategoryCancel() {
      return (this.ticketChangeCategory = {
        show: false,
        ticketId: null,
        ticketSubject: null,
        categoryId: null,
      });
    },
    changeCategoryOk(response) {
      if (response.status == "ok") {
        if (this.ticketChangeCategory.selected) {
          for (var ticket of this.selectedTickets) {
            ticket.category = response.result.category_id;
            ticket.ticket_category = {
              id: response.result.category_id,
              name: response.result.category_name,
            };
          }
        } else {
          this.ticketChangeCategory.ticket.category =
            response.result.category_id;
          this.ticketChangeCategory.ticket.ticket_category = {
            id: response.result.category_id,
            name: response.result.category_name,
          };
        }
      }
      return this.changeCategoryCancel();
    },
    changePriority(ticket) {
      return (this.ticketChangePriority = {
        show: true,
        ticketId: ticket.id,
        ticketSubject: ticket.subject,
        priorityId: ticket.priority,
        ticket: ticket,
        selected:
          this.selectedTickets && this.selectedTickets.length > 1
            ? this.selectedTickets
            : null,
      });
    },
    changePriorityCancel() {
      return (this.ticketChangePriority = {
        show: false,
        ticketId: null,
        ticketSubject: null,
        priorityId: null,
      });
    },
    changePriorityOk(response) {
      if (response.status == "ok") {
        if (this.ticketChangePriority.selected) {
          for (var ticket of this.selectedTickets) {
            ticket.priority = response.result.priority_id;
            ticket.ticket_priority = {
              priority: response.result.priority_id,
              name: response.result.priority_name,
            };
          }
        } else {
          this.ticketChangePriority.ticket.priority =
            response.result.priority_id;
          this.ticketChangePriority.ticket.ticket_priority = {
            priority: response.result.priority_id,
            name: response.result.priority_name,
          };
        }
      }
      return this.changePriorityCancel();
    },
    changeStatus(ticket) {
      return (this.ticketChangeStatus = {
        show: true,
        ticketId: ticket.id,
        ticketSubject: ticket.subject,
        statusId: ticket.status,
        ticket: ticket,
        selected:
          this.selectedTickets && this.selectedTickets.length > 1
            ? this.selectedTickets
            : null,
      });
    },
    changeStatusCancel() {
      this.selectedTickets = null;
      return (this.ticketChangeStatus = {
        show: false,
        ticketId: null,
        ticketSubject: null,
        statusId: null,
      });
    },
    changeStatusOk(response) {
      if (response.status == "ok") {
        if (this.ticketChangeStatus.selected) {
          for (var ticket of this.selectedTickets) {
            this.streakStatusChange(
              ticket.ticket_status.name,
              response.result.status_name
            );
            ticket.status = response.result.status_id;
            ticket.ticket_status = {
              status: response.result.status_id,
              name: response.result.status_name,
            };
            if (!this.showAll) {
              this.removeByStatus(response.result.status_name);
            }
          }
        } else {
          this.streakStatusChange(
            this.ticketChangeStatus.ticket.ticket_status.name,
            response.result.status_name
          );
          this.ticketChangeStatus.ticket.status = response.result.status_id;
          this.ticketChangeStatus.ticket.ticket_status = {
            status: response.result.status_id,
            name: response.result.status_name,
          };
        }
        if (!this.showAll) {
          this.removeByStatus(response.result.status_name);
        }
      }
      return this.changeStatusCancel();
    },
    async mergedTicketsOk(response) {
      if (response.status == "ok") {
        this.showMergeTickets = false;
        await this.initStreakStatus();
        this.streakSelectedChange(
          this.currentState,
          this.currentState.notRemove
        );
      } else {
        this.showMergeTickets = false;
      }
    },
    viewNotes(ticket) {
      return (this.notes = {
        ticket: ticket,
        show: true,
      });
    },
    closeNotes() {
      return (this.notes = {
        show: false,
        ticket: { ticket_notes: [] },
      });
    },
    async searchTickets() {
      if (this.arquiveSearch == null || this.arquiveSearch == "") {
        return;
      }

      this.loading = true;
      await ticketsService.search(this.arquiveSearch).then((response) => {
        if (!response || response.length == 0) {
          return this.$toast.add({
            severity: "info",
            summary: `Resultado da Pesquisa`,
            detail: `Não existem tickets com o termo ${this.arquiveSearch}`,
            life: 3000,
          });
        }

        this.tickets = response.map((v) => ({
          ...v,
          created_at: new Date(`${v.created_at}`),
          updated_at: new Date(`${v.updated_at}`),
          assigned_to_name: v.assigned_to
            ? v.ticket_assigned_user.username
            : null,
          office: v.assigned_to ? v.ticket_assigned_user.office : "___",
        }));
      });
      this.expandedRowGroups = this.tickets.reduce(function (acc, curr) {
        if (!acc.includes(curr.ticket_status.name))
          acc.push(curr.ticket_status.name);
        return acc;
      }, []);
      return (this.loading = false);
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}

.align-center .p-column-header-content {
  justify-content: center !important;
}
th.align-center {
  justify-content: center !important;
}

tr.p-highlight {
  background: #bcbcbc !important;
}
</style>
